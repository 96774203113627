<template>
    <div>
        <div class="header flex">
            <div class="w-1/6">
                <div v-on:click = 'goBack' class="back-button flex">
                    <div class="arrow"><font-awesome-icon icon = 'arrow-left'></font-awesome-icon></div>
                    <div style = 'padding-top: 9px; padding-left: 5px;'>Back</div>
                </div>
            </div>
        </div>
        <div class="coi-card mt-5" >
            <div class="coi-card-border">
                <div class="flex-col coi-card-header">
                    <div class="flex ">
                        <div class="w-10/12 flex row-item">
                            <div class="fa-icon mr-2">
                                <font-awesome-icon icon = 'user-tie' />
                            </div>
                            <div>
                                <h1>{{vendor.name}}</h1>
                            </div>
                        </div>
                        <div class="w-2/12 flex">
                            <div v-if="vendor.status === 'green'" class = 'text-right w-full'>
                                <div>
                                    Compliant
                                </div>
                                <div class="fa-icon float-right"><font-awesome-icon icon = 'check'></font-awesome-icon></div>
                            </div>
                            <div v-else class = 'text-right w-full' >
                                <div class = 'float-left text-red-500' >
                                    Needs attention
                                </div>
                                <div class="fa-icon fa-icon-red float-right"><font-awesome-icon icon = 'exclamation-triangle'></font-awesome-icon></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-col coi-card-row">
                    <div class="flex">

                        <div class="w-1/2 flex-col details">
                            <div class="flex-col ">
                                <div>{{vendor.name || 'none' }}</div>
                                <div class="subscript">Vendor Name</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.address || 'none'}}</div>
                                <div class="subscript">Address</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.city || 'none'}}</div>
                                <div class="subscript">City</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.state || 'none'}}</div>
                                <div class="subscript">State</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.zip || 'none'}}</div>
                                <div class="subscript">zip</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.vendor_id || 'none'}}</div>
                                <div class="subscript">Vendor Id</div>
                            </div>
                        </div>
                        <div class="w-1/2 flex-col details">
                            <div class="flex-col">
                                <div>{{vendor.primary_contact || 'none'}}</div>
                                <div class="subscript">Primary Contact</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.email || 'none'}}</div>
                                <div class="subscript">Primary Email</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.phone || 'none'}}</div>
                                <div class="subscript">Primary Phone</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.ein || 'none'}}</div>
                                <div class="subscript">EIN</div>
                            </div>
                            <div class="flex-col">
                                <div>{{vendor.insurance_email || 'none'}}</div>
                                <div class="subscript">Insurance Emails</div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="flex-col mb-10">-->
<!--                    <div class="w-1/2 flex-col details">-->
<!--                        <router-link :to = "{name: 'vendor-edit', params: {id: vendor.id}}">-->
<!--                            <button class = 'edit-button coi-button'>Edit Details</button>-->
<!--                        </router-link>-->
<!--                    </div>-->
<!--                </div>-->
                <div class=" flex-col">
                    <div class="flex">
                        <div class="flex  w-full">
                            <div class="flex w-7/12 lg:w-5/12 header-item" >
                                <div>Policy Name</div>
<!--                                <div class="sort-none" v-if="sort.name === sortDirection[0]">&#8691;</div>-->
<!--                                <div class="sort-ascending" v-if="sort.name === sortDirection[1]">&#8679;</div>-->
<!--                                <div class="sort-descending" v-if="sort.name === sortDirection[2]">&#8681;</div>-->
                            </div>
                            <div class="flex hideable lg:w-2/12 header-item">
                                <div>Expiration</div>
<!--                            <div class="sort-none" v-if="sort.expiration === sortDirection[0]">&#8691;</div>-->
<!--                            <div class="sort-ascending" v-if="sort.expiration === sortDirection[1]">&#8679;</div>-->
<!--                            <div class="sort-descending" v-if="sort.expiration === sortDirection[2]">&#8681;</div>-->
                            </div>
                            <div class="flex w-4/12 xl:w-3/12 header-item">
                                <div>Agent Email</div>
<!--                                <div class="sort-none" v-if="sort.email === sortDirection[0]">&#8691;</div>-->
<!--                                <div class="sort-ascending" v-if="sort.email === sortDirection[1]">&#8679;</div>-->
<!--                                <div class="sort-descending" v-if="sort.email === sortDirection[2]">&#8681;</div>-->
                            </div>
                            <div class="flex w-1/12 xl:w-2/12 header-item">
                                <div class="hideablelg">Status</div>
<!--                                <div class="sort-none" v-if="sort.status === sortDirection[0]">&#8691;</div>-->
<!--                                <div class="sort-ascending" v-if="sort.status === sortDirection[1]">&#8679;</div>-->
<!--                                <div class="sort-descending" v-if="sort.status === sortDirection[2]">&#8681;</div>-->
                            </div>
                        </div>
                    </div>
                    <div class="scrollable-box">
                        <div v-for = "(policy, index) in vendor.policies" v-bind:key='policy.id'  class = 'flex-col  coi-card-row' :class=" {even: index%2===0}">
                            <div :class="policy.status">
                                <div class = 'w-full vendor-row flex'>
                                    <div class="w-full  clickable flex"  v-on:click = 'viewPolicy(policy.id)'>
                                        <div class=" w-7/12 lg:w-5/12 flex row-item">
                                            <div class = 'fa-icon'><font-awesome-icon icon = 'chess-pawn'></font-awesome-icon></div>
                                            <div class="row-text">{{policy.name}}</div>
                                        </div>
                                        <div class="hideable  lg:w-2/12 flex row-item">
                                            <div class="row-text">{{policy.expiration}}</div>
                                        </div>
                                        <div class="w-4/12 xl:w-3/12 flex row-item ">
                                            <div class="row-text">{{policy.agent_email || 'Not yet Notified'}}</div>
                                        </div>
                                        <div class="w-1/12 xl:w-2/12 flex row-item">
                                            <div class="w-2/3 flex">
                                                {{ policy.status_group}}
                                            </div>
                                            <div class="w-1/3">
                                                <div v-if="policy.status === 'green'" class="status-mark">&#x2713;</div>
                                                <div v-else class="status-mark">&#x26a0;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {SELECT_VENDOR} from "../../../store/actions/vendor";
    import {SELECT_POLICY} from "../../../store/actions/policy";
    import {GET_STATUSES} from "../../../store/actions/policy";

    export default {
        name: 'Vendor',
        data() {
            return {
                show: 0,
                sort:{
                    name: '',
                    expiration: '',
                    email: '',
                    status: '',
                },
                sortDirection:['','asc','desc'],
            }
        },
    methods: {
        sortBy(col){
            let index = this.sortDirection.indexOf(this.sort[col]);
            index >=2 ? index = 0 : index ++;
            this.sort[col] = this.sortDirection[index];
            for (const column in this.sort){
                if (column !== col && this.sort.hasOwnProperty(column)){
                    this.sort[column] = this.sortDirection[0];
                }
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        showMore() {
            if(this.show) {
                this.show = false;
            }
            else {
                this.show = true;
            }
        },
        viewPolicy(id) {
            this.$store.dispatch(SELECT_POLICY, id).then(() => {
                this.$router.push({name: 'policy', params: {id: id}});
            }).catch();
        },
        rowClass({row}) {
           return row.status === 'green'? 'green' : 'red'
        }
    },
    computed: {
      vendor: function() {
        return this.$store.getters.getVendor;
      },
        statuses: function() {
          return this.$store.getters.getStatuses;
        }
    },
    mounted() {
        this.$store.dispatch(SELECT_VENDOR, this.$route.params.id).then(() => {}).catch();
        this.$store.dispatch(GET_STATUSES).then(() => {}).catch();

    }
  }
</script>

<style scoped>
    .details{
        margin: 5px 50px;
    }
    .details .flex-col{
        margin: 15px 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .details .subscript {
        border-top: 1px solid #e3e8ff;
        margin-top: 3px;
        padding-top: 2px;
    }
    .edit{
        margin: 0 10px 0 15px;
    }
    .details-box {
        overflow: hidden;
        height: 120px;
    }
    .vendor-row .row-item{
        /*vertical-align: bottom;*/
    }
    .row-text {
        padding-top: 8px;
    }
    .header-item{
        text-align: center;
        padding: 0 10px;
    }
    .row-item{
        height:40px;
        padding: 0 10px;

    }
    .actions{
        text-align: right;
        border-left: 1px solid #e3e8ff;
        padding: 0 15px 0 15px;
        margin: 0 0 0 15px;
    }
    .even .vendor-row .actions{
        border-left: 1px solid #ffffff;
    }
    .el-table .cell, .el-table tr {
        height: 100px;
    }
    @media only screen and (max-width: 1280px) {
        .actions {
            display: none;
        }
    }
</style>
